<template>
  <div class="goodsType whp100">
    <el-cascader @visibleChange="changeFun" v-model="goods_type" :options="typeSelect" :props="typeSelectProps" style="width:100%;" />
  </div>
</template>

<script>
export default {
  name: 'goodsType',
  props: {

    rid: {
      types: [Number,String],
      default: '',
    },

    is_all:{
      types:Boolean,
      default:true
    },

    value:{
      types:Array,
      default:[]
    },

    valueId:{
      types:Array,
      default:[]
    },

    in_id:{
      types:[Array,String],
      default:[]
    },

    // 回显一维数组，对应的是商品分类的id
    datas:{
      types:Array,
      default:[0]
    },

    data_val:{
      types:Object,
      default:() => {},
    }
  },

  emits: ['update:value','update:valueId','typeChange'],

  watch: {

    in_id(newVal, oldVal) {
      this.up_in_id();
      console.log(`${oldVal} 变 ${newVal}`);
    },

    rid(newVal, oldVal) {
      this.getData();
      console.log(`${oldVal} 变 ${newVal}`);
    },

    value(newVal, oldVal) {
      console.log(oldVal);
      console.log('变');
      console.log(newVal);

      if(!newVal){
        this.goods_type = [0];
        return false;
      }

      var typeVal = [];
      newVal.forEach((val) => {
        typeVal.push(val.tid)
      })

      this.goods_type = typeVal
    },

    datas(newVal, oldVal) {
      this.goods_type = this.datas
      console.log(`${oldVal} 变 ${newVal}`);
    },

    data_val(newVal, oldVal) {
      console.log(oldVal);
      console.log('变');
      console.log(newVal);


    }
  },

  data() {
    return {
      // 多级选择器
      typeSelectProps: {
        label: "goods_type_name",
        value: "id",
        checkStrictly: true,
        expandTrigger: 'hover',
      },
      typeSelect: [],
      typeIdName: 0,
      goods_type:this.datas,
      in_id_obj:{},
    }
  },

  created() {
    this.up_in_id();
    this.getData();
  },

  methods: {

    changeFun(res){
      if(!res){
        var that = this;
        var typeVal = [];
        // var typeId = [0,0,0,0];
        var typeId = [];

        that.goods_type.forEach((val,key) => {

          typeId[key] = val;

          var sort = 0;
          var name = '全部';
          if(val > 0){
            sort = that.typeIdName[val].goods_type_sort
            name = that.typeIdName[val].goods_type_name
          }

          typeVal.push({
            tid:val,
            sort:sort,
            name:name,
          })
        })

        // console.log('---------分类选择---------K')
        // console.log('typeVal',typeVal)
        // console.log('typeId',typeId)
        // console.log('---------分类选择---------E')
        that.$emit('update:valueId', typeId);
        that.$emit('update:value', typeVal);
        that.$emit('typeChange', typeVal);

      }
    },

    // 处理过滤
    up_in_id(){
      var in_id = this.in_id;
      if(typeof in_id == 'string'){
        in_id = in_id.split(',')
      }

      var in_id_obj = {};
      in_id.forEach((val) => {
        in_id_obj[val] = 1;
      })
      this.in_id_obj = in_id_obj;

    },

    // 获取数据列表
    getData() {
      var that = this

      that.util.request({
        url: '/coom/port/getAreaGoodsType',
        data: {
          rid: that.rid
        },
        success(res) {

          var type_y = res.data.type;
          var type_v = [];
          type_y.forEach((val) => {
            if (that.in_id_obj[val.id] || (that.is_all || (!that.is_all && val.id != 0))) {
              type_v.push(val);
            }
          })
          that.typeSelect = type_v

          if (res.data.types && res.data.types.length > 0) {
            that.typeIdName = {};
            res.data.types.forEach((val) => {
              that.typeIdName[val.id] = val;
            })
          }

        },
        fail(res) {
          console.log('page_fail', res)
        }
      })
    },

  }
}
</script>
  
<style lang="less"></style>
  