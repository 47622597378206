import axios from "axios"

// element Ui
import {
	// ElMessage,
	ElNotification,
	ElMessageBox,
	ElLoading 
} from 'element-plus'

// 路由
import router from '@/router'

// vuex 数据
import store from '@/store'

//	工具库
export const util = {
	
	//	配置参数
	config: {
		// apiHost:'http://qsw.com',
		// fileHost:'http://qsw.com/shop/images/',

		apiHost:'https://api.qingsuiwang.com',
		fileHost:'https://api.qingsuiwang.com/shop/images/',

		prefix:'qsw_',
		
		// 高德地图
		mapKey: '1683e3941ae97d0310d6875da0f0fac4',
		mapCode: "d316bd6f07641ad6a58e2d4ccbfc64c4",
	},

	parseFloat(val,cou = 2){
		return parseFloat(parseFloat(val).toFixed(cou));
	},

	isRank(keys){
		var rankBut = store.state.rankBut;
		// console.log('typeof rankBut[keys]',typeof rankBut[keys])
        var off = false
        if(keys.length > 0){
            if(typeof rankBut[keys] !== 'undefined'){
                off = true
            }else{
              off = false
            }
        }else{
          off = false
        }

		return off;
	},

	jsonObj(data){
		return JSON.parse(JSON.stringify(data))
	},

	loginIs(fun = '',urlIs = true,load = false){
		var that = this;

		var token = store.state.token;
		if(!token){
			that.getLogin();
		}

		var timesD = that.getTimes();
		var timesJ = store.state.user_times;

		if(token && timesD - timesJ <= 43200 && !load){
			fun ? fun(true) : '' ;
		}else{
			token = that.localGet('token')
			if(token){

				that.request({
					url:'/login/user/info',
					load:false,
					data:{
					},
					success(res){
						var user = res.data;
						console.log(user)

						util.setLogin(user)

						fun ? fun(true) : '' ;

					},
					fail(e){ 
                        console.log(e)
					}
				})

			}else{
				if(urlIs){
					router.push({
						path: '/login'
					})
				}else{
					fun ? fun(false) : '' ;
				}
			}
		}

	},

	setLogin(user,way = true){
		if(way){
			store.state.user = user
			util.localSet('user',store.state.user)
			store.state.token = user.token
			util.localSet('token',store.state.token)
			store.state.port = user.qsw_user.login_port
			util.localSet('port',store.state.port)
			store.state.rank = user.rank;
			util.localSet('rank',store.state.rank)
			store.state.nav = user.nav;
			util.localSet('nav',store.state.nav)

			store.state.user_times = util.getTimes();
			util.localSet('user_times',store.state.user_times)
			
		}else{
			store.state.user = 0
			util.localSet('user',store.state.user)
			store.state.token = 0
			util.localSet('token',store.state.token)
			store.state.port = 0
			util.localSet('port',store.state.port)
			store.state.rank = 0
			util.localSet('rank',store.state.rank)
			store.state.nav = 0
			util.localSet('nav',store.state.nav)

			store.state.user_times = 0
			util.localSet('user_times',0)

		}
	},

	getLogin(){
		store.state.user = util.localGet('user')
		store.state.token = util.localGet('token')
		store.state.port = util.localGet('port')
		store.state.rank = util.localGet('rank')
		store.state.nav = util.localGet('nav')
		store.state.user_times = util.localGet('user_times')
	},

	loginUrl(way = false){
		if(store.state.user != 0 && store.state.token != 0){
			if(store.state.port == 1){
				if(way){
					return '/admin/index/index';
				}else{
					router.push({
						path: '/admin/index/index'
					})
				}
			}else if(store.state.port == 2){
				if(way){
					return '/agency/index/index';
				}else{
					router.push({
						path: '/agency/index/index'
					})
				}
			}else if(store.state.port == 3){
				if(way){
					return '/area/index/index';
				}else{
					router.push({
						path: '/area/index/index'
					})
				}
			}else if(store.state.port == 4){
				if(way){
					return '/granary/index/index';
				}else{
					router.push({
						path: '/granary/index/index'
					})
				}
			}else if(store.state.port == 5){
				if(way){
					return '/supply/index/index';
				}else{
					router.push({
						path: '/supply/index/index'
					})
				}
			}
		}
	},

	logout(){
		util.setLogin({},false);
		router.push({
			path: '/login'
		})
	},

	//	加载效果
	showLoading() {
		ElLoading.service({
			background: "rgba(0,0,0,.3)",
			text: "加载中...",
		})
	},

	//	关闭加载效果
	hideLoading() {
		ElLoading.service({}).close();
	},

	//	弹窗
	alert(obj,types = 'success') {
		//	这个地方说白了就是 如果只是想提示一句话一个文本的话 就只是弹个小tips
		if (typeof obj == "string" || typeof obj == "number") {
			// ElMessage({
			// 	message: obj,
			// 	type: types, // error
			// 	duration: 2000,
			// 	showClose: false,
			// })

			ElNotification({
				title: '系统提示',
				message: obj,
				duration: 2000,
				position:'bottom-right',
				type: types,
			})

			return
		}

		//	标题
		obj.title = obj.title ? obj.title : ""
		//	提示内容
		obj.value = obj.value ? obj.value : "提示信息"
		//	确定文本
		obj.confirmText = obj.confirmText ? obj.confirmText : "确定"
		//	取消按钮
		obj.cancel = obj.cancel === false ? false : true
		obj.modal = obj.modal === true ? true : false
		//	取消文本
		obj.cancelText = obj.cancelText ? obj.cancelText : "取消"

		obj.type = obj.type ? obj.type : 'warning'
		// 'warning'
		// 'error'
		// 'success'
		
		// 返回Promise对象
		ElMessageBox.alert(obj.value,obj.title,{
			confirmButtonText: obj.confirmText,
			cancelButtonText: obj.cancelText,
			showCancelButton:obj.cancel,

			closeOnClickModal: obj.modal,
			closeOnPressEscape: obj.modal,

			dangerouslyUseHTMLString: true,
			draggable: true,
			center: true,
			showClose: false,
			type: obj.type,
		})
		.then(() => {
			if (typeof obj.success != 'undefined') {
				obj.success();
			}
		})
		.catch(() => {
			if (typeof obj.fail != 'undefined') {
				obj.fail();
			}
		})
	},

	prompt(obj){

		obj.title = obj.title ? obj.title : ""
		//	提示内容
		obj.value = obj.value ? obj.value : "提示信息"
		//	确定文本
		obj.confirmText = obj.confirmText ? obj.confirmText : "确定"
		//	取消文本
		obj.cancelText = obj.cancelText ? obj.cancelText : "取消"

		obj.type = obj.type ? obj.type : 'success'

		ElMessageBox.prompt(obj.value, obj.title, {
			confirmButtonText: obj.confirmText,
			cancelButtonText: obj.cancelText,
		})
		.then(({ value }) => {
			if (typeof obj.success !== 'undefined') {
				obj.success(value);
			}
		})
		.catch(() => {
			if (typeof obj.fail !== 'undefined') {
				obj.fail();
			}
		})
	},

	//	发送网络请求
	request(params) {
		//	返回方法
		return new Promise((resolve, reject) => {
			//	请求地址
			params.url = util.config.apiHost + (params.url ? params.url : '')

			// 判断是否加载
			// var loadId = 0;

			if (typeof params.load === 'undefined') {
				params.load = true;
			}

			if (params.load) {
				if(store.state.requestCou <= 0){
					if(store.state.requestCou < 0){
						store.state.requestCou = 0;
					}
					store.state.requestCou += 1;
					
					util.showLoading()
					// loadId = setTimeout(function(){
					// 	util.showLoading()
					// },100)
				}
			}

			//	请求方式
			params.method = params.method ? params.method : "POST"

			if(!params.data){
				params.data = {};
			}

			//	定义头部
			params.headers = {
				'Authorization': 'Bearer '+store.state.token,  // 看到这个token了没有，它在在第一步的第三行出现过~

				...params.headers,
			}

			params.data = params.data ? params.data : {};
			//	发送数据
			if (params.method == "POST") {
				if (!params.headers) {
					params.data = params.data ? JSON.stringify(params.data) : ""
				}
			} else {
				//	设置get传参
				params.params = params.data
				//	移除post类型下的data 改用params
				delete(params.data);
			}

			//	定义数据

			//	数据类型
			params.dataType = "json"

			// 发起网络请求
			axios(params)
			.then(res => {

				// if(loadId){
				// 	clearInterval(loadId)
				// }

				//
				if (params.load) {
					store.state.requestCou -= 1;
					if(store.state.requestCou <= 0){
						store.state.requestCou  = 0;
						util.hideLoading()
					}
				}

				// 如果
				if (
					res.data.code >= 400 && res.data.code <= 500
					|| res.code == 0
				) {

					if(res.data.code == 444){

						util.alert({
							type:'error',
							cancel:false,
							value:'您的登录状态已丢失，请重新登录',
							success:() => {
								util.logout();
							}
						});

					}else{
						if(res.code == 0){
							// 登出
							util.alert({
								type:'error',
								cancel:false,
								value:'系统错误',
							});
						}else{
							// 登出
							util.alert({
								type:'error',
								cancel:false,
								value:'暂无权限',
							});
							return
						}
					}
					
				}

				//	帮助突破技术瓶颈，提升思维能力
				params.success ? params.success(res.data) : () => {}

				resolve(res.data)
			})
			.catch(err => {
				//
				if (params.load) {
					util.hideLoading()
				}

				params.fail ? params.fail(err) : () => {}

				if(err.code == "ERR_BAD_RESPONSE"){
					// 登出
					util.alert({
						type:'error',
						cancel:false,
						value:'系统错误',
					});
					return
				}


				// 失败
				reject(err)
			})
		})
	},

	// 对二补齐
	toTwo(str) {
		// 格式化字符
		let num = String(Number(str));

		// 判断时间
		if (num.length < 2) {
			num = '0' + num;
		}

		// 返回时间
		return num;
	},

	// 获取时间戳
	getTimes() {
		// 当前时间
		const date = new Date()
		// 当前时间
		const now = parseInt(date.valueOf() / 1000)
		// 返回时间
		return now
	},

	getBatch(){
		var y = parseInt(util.getDate('yyyy'));
		var m = util.getDate('MM');

		const date = new Date();
		const year = date.getFullYear();
		const month = date.getMonth() + 1; // 月份从0开始计算，需要加1
		var lastDay = new Date(year, month, 0).getDate();

		lastDay = parseInt(lastDay);
		lastDay = lastDay > 9 ? lastDay : '0'+lastDay;

		var batch = [y+'-'+m+'-01',y+'-'+m+'-'+lastDay];
		return batch;
	},

	/**
	 * 时间戳处理成时间
	 * @param {String} str 规则如下
	 * 时间格式		月、日、时、分、秒 单个字母不补0
	 * yyyy	年
	 * MM	月
	 * dd	日
	 * WW	周
	 * HH	小时	24小时制
	 * hh	小时	12小时制
	 * mm	分钟
	 * ss	秒
	 * a	am/pm
	 * 比如 'yyyy-MM-dd HH:mm:ss 周w a' 返回2023-02-06 11:19:19 周一 pm
	 * @param {Number} timestamp 需要处理的时间戳
	 * @return {String} 日期字符串
	 */
	getDate(str, timestamp) {
		// 当前时间
		const nowDate = new Date()
		// 周列表	歪果仁把周日放在第一位
		let weekList = ['日', '一', '二', '三', '四', '五', '六']

		// 格式化字符串
		if (!str || typeof str != 'string') {
			str = 'yyyy-MM-dd HH:mm:ss'
		}

		// 格式化时间戳
		if (!timestamp) {
			if(timestamp === 0){
				return ''
			}else{
				timestamp = nowDate.valueOf()
			}
		} else if (String(timestamp).length <= 10) {
			timestamp = timestamp * 1000
		}

		// 时间
		const date = new Date(timestamp)

		// 获取对应时间
		let year = date.getFullYear(),
			moth = date.getMonth() + 1,
			day = date.getDate(),
			week = date.getDay(),
			hour = date.getHours(),
			minute = date.getMinutes()
			// second = date.getSeconds()

		// 处理年
		str = str.replace('yyyy', year)
		// 处理月份
		str = str.replace('MM', this.toTwo(moth))
		str = str.replace('M', moth)
		// 处理日
		str = str.replace('dd', this.toTwo(day))
		str = str.replace('d', day)
		// 处理周
		str = str.replace('w', weekList[week])
		// 处理小时
		if (str.match('h')) {
			// 12小时制
			hour = hour > 12 ? hour - 12 : hour
			// 12小时制
			str = str.replace('hh', this.toTwo(hour))
			str = str.replace('h', hour)
		} else if (str.match('H')) {
			// 24小时制
			str = str.replace('HH', this.toTwo(hour))
			str = str.replace('H', hour)
		}
		// 处理分钟
		str = str.replace('mm', this.toTwo(minute))
		str = str.replace('m', minute)
		// 处理秒
		str = str.replace('ss', this.toTwo(minute))
		str = str.replace('s', minute)
		// 处理是上午还是下午
		let a = hour > 12 ? 'am' : 'pm'
		str = str.replace('a', a)
		return str
	},

	localGet(str = ''){
		var dataJson = localStorage.getItem(this.config.prefix + str);
        if(dataJson && dataJson != 'undefined' && dataJson != 'null'){
            return JSON.parse(dataJson);
        }else{
            return false;
		}
	},

	localSet(str = '',data = ''){
		localStorage.setItem(this.config.prefix + str,JSON.stringify(data));
	},

	load_times_if(str){

		if(store.state.load_times){
			var load_times = store.state.load_times[str];
			if(load_times != 'undefined' && load_times){
				// 判断服务器中的版本号
				console.log(str+'_load_times',load_times)

				// 缓存数据中的版本号
				var log_times = util.localGet(str);
				if(!log_times || load_times != log_times){
					util.localSet(str,load_times);
					return false;
				}else{
					return true;
				}

			}else{
				// 没有版本号
				return false;
			}
		}else{
			// 没有版本号
			return false;
		}

	},

	loads(fun = '',log = []){
		var that = this;
		fun ? fun() : '';

		var load_times_up = parseInt(that.localGet('load_times_up'));
		var times = that.getTimes();

		if(!store.state.system || times - load_times_up > 120){
			that.localSet('load_times_up',times);
			that.request({
				url:'/api/system/defaults',
				load:false,
				data:{},
				success(e){

                    var configs = e.data.config

					store.commit('setState', {
						key: 'system',
						value: configs
					})

                    that.localSet('load_times_up',configs);

					for(var k in log){
						if(!that.load_times_if(log[k]+'_times')){
							fun ? fun() : '';
							return false;
						}
					}
				},
				fail(e){
                    console.log(e)
				}
			})
		}else{
            for(var k in log){
                if(!that.load_times_if(log[k]+'_times')){
                    fun ? fun() : '';
                    return false;
                }
            }
        }

	},

	get_type(types,str = '',j = '、'){
		str = str+'';

		var arr = [];
		str.split(',').forEach((val) => {
			if(val && types[val] != undefined){
				arr.push(types[val].goods_type_name)
			}
		})

		return arr.join(j)
	},

	// 平台格式化
	get_p(str = '',j = '、'){
		str = str+'';

		var arr = [];
		str.split(',').forEach((val) => {
			if(val == 1){
				arr.push('安卓');
			}else if(val == 2){
				arr.push('苹果');
			}else if(val == 3){
				arr.push('鸿蒙');
			}else if(val == 4){
				arr.push('微信小程序');
			}else if(val == 5){
				arr.push('微信公众号');
			}else if(val == 6){
				arr.push('支付宝小程序');
			}else if(val == 7){
				arr.push('抖音');
			}else{
				arr.push('快手');
			}

		})

		return arr.join(j)
	},

	// 平台格式化
	get_bsc(str = '',j = '、'){
		str = str+'';

		var arr = [];
		str.split(',').forEach((val) => {
			if(val == 1){
				arr.push('B端');
			}else if(val == 2){
				arr.push('S端');
			}else if(val == 3){
				arr.push('C端');
			}
		})

		return arr.join(j)
	},

	// 平台格式化
	get_is_type(str = '',j = '、'){
		str = str+'';

		var arr = [];
		str.split(',').forEach((val) => {
			if(val == 1){
				arr.push('常规');
			}else if(val == 2){
				arr.push('钜惠');
			}else if(val == 3){
				arr.push('秒杀');
			}else if(val == 4){
				arr.push('满赠');
			}else if(val == 5){
				arr.push('折扣');
			}else if(val == 6){
				arr.push('积分');
			}
		})

		return arr.join(j)
	},

}

export default util
